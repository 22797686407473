body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.LongTextThreeDotAboutSectionBody {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 17;
  overflow: hidden;
  max-height: 412px;
  width: 100%;
}

.LongTextThreeDotAboutSectionFooter {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  max-height: 120px;
  width: 100%;
}
.footerLogo{
  max-width: 185px;
}

.LongTextNewstitle
{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  max-height: 73px;
  width: 100%;
}

.search-container {
 
  

  border-radius: 10px;
  padding: 20px;
  margin: auto;
  position: relative;

  bottom: 60px; /* Adjust based on where you want it to appear */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background for better readability */

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better separation */



}
.carousel-indicators {
  right: unset;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link 
{
   color: #495057; 
   background-color: #e7f5fa; 
   border-color: #0a94d0 #0a94d0 #0a94d0;
}

/* Ensure the submenu is hidden by default */
.dropdown-submenu {
  position: relative !important;
}

.dropdown-submenu .dropdown-menu {

  display: none !important;

  top: 10% !important; /* Adjust this value as needed to move the submenu upwards */
  left: 0; /* Align the submenu with the parent */
  margin-top: 0;
  
}

/* Show the submenu when hovering over the parent menu item */
.dropdown-submenu:hover .dropdown-menu {
  display: block !important;
 
}