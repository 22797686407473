.skeleton{
    background: #ddd;
   
}
.skeleton.text{
    width: 100%;
    height: 12px;
}
.skeleton.title{
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
}
.skeleton.avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.skeleton.thumbnail{
    width: 100px;
    height: 100px;
}