.dropdown-submenu {
    position: relative;
  }
  
  .dropdown-submenu .dropdown-menu {
    top: 10%;
    left: 100%;
    margin-top: -1px;
  }
  
  .navbar-nav li:hover > ul.dropdown-menu {
      display: block;
  }